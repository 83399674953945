import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Typography from '~/atoms/Typography';
import theme from '~/styles/styledComponentsTheme';
import * as S from './index.styles';

export const ArticleCard = ({
  linkUrl,
  title,
  authorSlug,
  authorName,
  thumbnailUrl,
  thumbnailAlt,
  caption,
  // subtitle,
  tags = [],
}: {
  linkUrl: string;
  title: string;
  authorSlug?: string;
  authorName?: string;
  thumbnailUrl: string;
  thumbnailAlt?: string;
  caption?: string;
  // subtitle: string;
  tags?: string[];
}) => (
  <S.Container to={linkUrl}>
    <S.ImageContainer>
      <svg viewBox="0 0 1 1" />
      <S.StyledImage url={thumbnailUrl} alt={thumbnailAlt} />
    </S.ImageContainer>
    {caption && <S.Caption>{caption}</S.Caption>}
    {authorName && (
      <S.Byline>
        by{' '}
        {authorSlug ? (
          <a href={`/wedding-advice/author/${authorSlug}`} onClick={(e) => e.stopPropagation()}>
            {authorName}
          </a>
        ) : (
          <span>{authorName}</span>
        )}
      </S.Byline>
    )}
    <S.TitleContainer>{title}</S.TitleContainer>
    {/* <S.SubtitleContainer>{subtitle}</S.SubtitleContainer> */}
    <S.ReadArticle>
      <Typography variant="h6" color={theme.coldGrey} customStyles="width: unset; font-weight: 500; line-height: 20px;">
        Watch Now
      </Typography>
      <S.ArrowWrapper>
        <FontAwesomeIcon icon={faAngleRight} color={theme.darkNightBlack} />
      </S.ArrowWrapper>
    </S.ReadArticle>
    {/* Temporarily hiding tags. If you uncomment this, remove the div below this comment. 
    <div style={{ display: 'flex', margin: '10px 0 28px', minHeight: '26px' }}>
      {tags.map((tag, index) => (
        <S.TagContainer key={index}>{tag}</S.TagContainer>
      ))}
    </div> */}
    <div style={{ marginBottom: '28px' }} />
  </S.Container>
);
