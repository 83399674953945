import styled from 'styled-components';
import LSTVImage from '~/components/LSTVImage';
import LSTVLink from '~/components/LSTVLink';
import theme from '~/styles/styledComponentsTheme';

export const Container = styled(LSTVLink)`
  min-width: 0;
  text-decoration: none;
  color: inherit !important;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: grid;

  & > * {
    grid-area: 1/1;
  }
`;

export const StyledImage = styled(LSTVImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

export const Byline = styled.div`
  font-size: 15px;
  color: ${theme.grey_1_0};
  margin: 10px 0;
`;

export const Caption = styled.div`
  font-size: 15px;
  color: ${theme.grey_1_0};
  margin: 10px 0;
`;

export const TitleContainer = styled.div`
  font-size: 23px;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${(props) => props.theme.darkNightBlack};
  margin-top: 8px;
`;

export const SubtitleContainer = styled.div`
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${(props) => props.theme.coldGrey};
  line-height: 17.85px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TagContainer = styled.div`
  font-size: 12px;
  background-color: ${(props) => props.theme.blueGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  height: 26px;
  border-radius: 30px;

  & + & {
    margin-left: 9px;
  }
`;

export const ReadArticle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
`;

export const ArrowWrapper = styled.span`
  margin: 1px 0px 1px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 1px #707070;
  border-radius: 60px;
  width: 24px;
  height: 24px;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  margin: 25px 0;
`;
