import {
  // FRONT_END_SETTINGS_HOME_PAGE_TITLE,
  USER_NRT_REPORT_BUFFER_SCOPE_DAY,
  USER_NRT_REPORT_BUFFER_SCOPE_WEEK,
  USER_NRT_REPORT_BUFFER_SCOPE_MONTH,
  USER_NRT_REPORT_CARD_IMPRESSION,
  USER_NRT_REPORT_VENDOR_CARD_MENTION_IMPRESSION,
} from '~/globals';
import { getYearWeekStringFromDate } from '~/utils/LSTVUtils';
import { userEventsService } from '~/api/services/userEventService';

// AK: determine if we actually need to hold any parts of the application state in local storage

// import { BUSINESSROLETYPES, BUSINESSSROLECAPTYPES, DIRECTORIES } from './businessesDefault';
// import { volatileState } from './reducer';

// export const loadState = () => {
//   console.warn("LOADING localstorage <--<-<-<-<-<-<-<-");
//   // Include initial state
//   const initialState = {
//       version: process.env.APP_VERSION,
//       user: {
//           loggedIn: false,
//       },
//       app: {
//           cachedData: {
//               frontEndSettings: {
//                   homePageTitle: FRONT_END_SETTINGS_HOME_PAGE_TITLE,
//                   systemNotifications: [],
//                   downtime: [],
//               },

//           },
//           layout: {
//               showFooter: true,
//           },
//           businessRoles: {
//               businessTypes: BUSINESSROLETYPES,
//               businessCapacityTypes: BUSINESSSROLECAPTYPES
//           },
//           directories: {
//               ...DIRECTORIES
//           }
//       },
//       volatile: {
//           ...volatileState,
//       },
//   };

//   try {
//       const serializedState = localStorage.getItem(`state`);
//       if (serializedState === null) {
//           return initialState;
//       } else {
//           const parsed = JSON.parse(serializedState);

//           if(Object.keys(parsed).includes('version') && parsed.version === process.env.APP_VERSION){
//               //If the version in ss === cureent app version, return ss otherwise return null
//               return parsed
//           } else if(Object.keys(parsed).includes('user') && parsed.user?.loggedIn) {
//               return { ...initialState, user: parsed.user}
//           }
//           return initialState
//       }

//   } catch (err) {
//       return initialState;
//   }
// };

// export const saveState = (state) => {
//   try {
//       const serializedState = JSON.stringify(state);
//       localStorage.setItem('state', serializedState);
//   } catch (e) {
//       console.info(e);
//   }
// };

export const recordNonRealTimeUserEvent = (domain, bufferScope, payload) => {
  // only run this in the browser not the server, obvs
  if (typeof window === 'undefined' || !localStorage) return;

  // do we have a user event buffer setup in local storage?
  if (localStorage.getItem('userEventsBuffer') === null) localStorage.setItem('userEventsBuffer', JSON.stringify({}));

  let buffer = JSON.parse(localStorage.getItem('userEventsBuffer'));
  let key;
  let todayKey = new Date().toISOString().split('T')[0];
  let thisWeekKey = getYearWeekStringFromDate(new Date());
  let thisMonthKey = `${new Date().getMonth()}`;

  switch (bufferScope) {
    case USER_NRT_REPORT_BUFFER_SCOPE_DAY:
      key = todayKey;
      break;
    case USER_NRT_REPORT_BUFFER_SCOPE_WEEK:
      key = thisWeekKey;
      break;

    case USER_NRT_REPORT_BUFFER_SCOPE_MONTH:
      key = thisMonthKey;
      break;
  }

  if (!(key in buffer)) {
    buffer[key] = {};
  }

  switch (domain) {
    case USER_NRT_REPORT_CARD_IMPRESSION:
    case USER_NRT_REPORT_VENDOR_CARD_MENTION_IMPRESSION:
      if (!(domain in buffer[key])) {
        buffer[key][domain] = {};
      }

      if (!(payload.slug in buffer[key][domain])) {
        buffer[key][domain][payload.slug] = { count: 1, type: payload.type };
      } else {
        buffer[key][domain][payload.slug].count += 1;
      }
      break;
  }

  // do we need to update the server?
  const report = Object.keys(buffer)
    .filter((d) => d !== todayKey && d !== thisWeekKey && d !== thisMonthKey)
    .map((d) => {
      return { key: d, scope: bufferScope, data: buffer[d] };
    });

  if (report.length > 0) {
    let res = userEventsService().reportBufferedUserEvents(report);
  }

  report.forEach((d) => {
    delete buffer[d.key];
  });

  localStorage.setItem('userEventsBuffer', JSON.stringify(buffer));
};
