import dynamic from 'next/dynamic';
import { Component } from 'react';
import Gallery from 'react-photo-gallery';
import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import { Media } from '~/styles/media';
// load on the client because it makes use of hammerjs which requires window to be present
const ImageViewer = dynamic(() => import('./PhotoGalleryCarousel'), { ssr: false });

const PhotoGalleryContainer = styled.div`
  width: 100%;
  display: block;
  height: auto;
`;

const Container = styled.div<{ $position: string; $width: number; $height: number; $top?: number; $left?: number }>`
  position: ${(props) => props.$position};
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  ${(props) => props.$top !== undefined && `top: ${props.$top}px;`}
  ${(props) => props.$left !== undefined && `left: ${props.$left}px;`}
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 2px;

  img {
    transition: all 0.2s linear;
  }

  &:hover {
    img {
      opacity: 0.5;
    }
  }
`;

const Image = styled.img`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: 0;
  display: block;

  @media ${LSTVGlobals.UserDevice.isMobileOrTablet} {
    border: 1px dotted white;
    box-sizing: border-box;
    margin: 2px;
  }
`;

class PhotoGallery extends Component<
  {
    images: any[];
    title: string;
    payload: any;
  },
  {
    viewerIsOpen: boolean;
    currentImage: number;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      viewerIsOpen: false,
      currentImage: 0,
    };
  }

  openLightBox = (index) => {
    this.setState({
      ...this.state,
      viewerIsOpen: true,
      currentImage: index,
    });
  };

  openLightBoxMobile = (event, index) => {
    this.setState({
      ...this.state,
      viewerIsOpen: true,
      currentImage: index,
    });
  };

  closeLightBox = () => {
    this.setState({
      ...this.state,
      viewerIsOpen: false,
    });
  };

  renderImageRow = (event) => {
    return (
      <Container
        onClick={() => this.openLightBox(event.index)}
        $position="relative"
        key={event.key}
        $width={event.photo.width}
        $height={event.photo.height}
      >
        <ImageContainer>
          <Image alt={event.photo.alt} width={event.photo.width} height={event.photo.height} src={event.photo.src} />
        </ImageContainer>
      </Container>
    );
  };

  renderImageColumn = (event) => {
    return (
      <Container
        onClick={() => this.openLightBox(event.index)}
        $position="absolute"
        key={event.key}
        $top={event.top}
        $left={event.left}
        $width={event.photo.width}
        $height={event.photo.height}
      >
        <Image width={event.photo.width} height={event.photo.height} alt={event.photo.alt} src={event.photo.src} />
      </Container>
    );
  };

  render() {
    let photos = this.props.images.map((image, index) => {
      return {
        key: index.toString(),
        src: image.url,
        width: image.width,
        height: image.height,
        title: this.props.title,
        businesses: this.props.payload,
      };
    });

    return (
      <PhotoGalleryContainer>
        <Media greaterThanOrEqual="md">
          <Gallery
            margin={0}
            direction={'row'}
            photos={photos}
            renderImage={this.renderImageRow}
            onClick={this.openLightBox}
          />
        </Media>
        <Media at="sm">
          <Gallery margin={0} columns={3} direction={'column'} photos={photos} renderImage={this.renderImageColumn} />
        </Media>
        <Media at="xs">
          <Gallery margin={0} columns={2} direction={'column'} photos={photos} renderImage={this.renderImageColumn} />
        </Media>

        {this.state.viewerIsOpen && (
          <ImageViewer
            currentIndex={this.state.currentImage}
            images={photos.map((x) => ({ ...x }))}
            showClose={true}
            handleClose={this.closeLightBox}
          />
        )}
      </PhotoGalleryContainer>
    );
  }
}

export default PhotoGallery;
