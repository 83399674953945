import React from 'react';
import PropTypes from 'prop-types';
import * as LSTVGlobals from '~/globals';
import styled from 'styled-components';
import PhotoGallery from '~/components/PhotoGallery';
import HorizontalScroll from '~/components/HorizontalScrollWrapper';
import { couplesNamesFromProperties, getLocationFullName, getVibesForCard, businessFromVideo } from '~/utils/LSTVUtils';
import { recordNonRealTimeUserEvent } from '~/utils/localStorage';
import LSTVCard, { CARD_TYPE_VIBE, CARD_TYPE_AD } from '~/components/cards/LSTVCard';
import { EventContext, EventParams, TrackImpression } from '~/globals/trackEvent';
import { minWidth } from '~/styles/media';
import VideoCard from '~/components/cards/VideoCard';
import VendorCard from '../cards/VendorCard';
import { ArticleCard } from '../cards/ArticleCard';
import { formatDate } from '~/utils/date';

/**
 * Create a style string from an object that maps 0 or more breakpoints (xs/sm/md/lg) to number of columns.
 */
const getResponsiveStyle = (numColumns) =>
  ['xs', 'sm', 'md', 'lg']
    .map((size) =>
      size in numColumns
        ? `@media ${minWidth(size)} { grid-template-columns:${' 1fr'.repeat(numColumns[size])}; }\n`
        : ''
    )
    .join('');

export const CardGridStyle = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 17px;
  ${(props) => getResponsiveStyle(props.$numColumns ?? { xs: 1, sm: 2, md: 4 })}
  height: auto;
`;

/**
 * Notice! CardGrid now expects to receive the full card
 * data in its `content` prop. Before it only expected slugs
 * and would fetch slug content on its own. But that's incompatbile
 * with our desire to render most pages statically with Next.js.
 *
 * TODO: convert to TypeScript
 */
class CardGrid extends React.Component {
  static contextType = EventContext;

  constructor(props) {
    super(props);
    this.state = {
      everInViewport: false,
    };
  }

  render() {
    if (this.props.content || this.props.cardType === LSTVGlobals.CONTENT_GRID_CONTENT_TYPE_PHOTO) {
      let cards = null;

      if (this.props.content) {
        cards = this.props.content
          .map((data, index) => {
            if (this.props.cardType === LSTVGlobals.CARD_SECTION_VIDEO) {
              let coupleNames = couplesNamesFromProperties(data.post_properties);
              let videographer = businessFromVideo(data.businesses, 'videographer');

              recordNonRealTimeUserEvent(
                LSTVGlobals.USER_NRT_REPORT_CARD_IMPRESSION,
                LSTVGlobals.USER_NRT_REPORT_BUFFER_SCOPE_DAY,
                {
                  slug: data.slug,
                  type: this.props.cardType,
                }
              );

              return (
                <VideoCard
                  key={index}
                  title={coupleNames}
                  videoLocation={getLocationFullName(data.location)}
                  videographerName={videographer ? videographer.name : ''}
                  videographerImageUrl={videographer?.logo_image_url}
                  duration={data.videosSources[0]?.duration}
                  thumbnailUrl={data.videosSources[0]?.thumbnail_url}
                  thumbnailAlt={`${coupleNames} Wedding Video`}
                  gifUrl={data.videosSources[0]?.preview_gif_url}
                  slug={data.slug}
                  vibes={getVibesForCard(data.vibes, 2)}
                  awards={data.awards}
                />
              );
            } else if (this.props.cardType === LSTVGlobals.CARD_SECTION_VIBE) {
              recordNonRealTimeUserEvent(
                LSTVGlobals.USER_NRT_REPORT_CARD_IMPRESSION,
                LSTVGlobals.USER_NRT_REPORT_BUFFER_SCOPE_DAY,
                {
                  slug: data.slug,
                  type: this.props.cardType,
                }
              );

              return (
                <LSTVCard
                  key={index}
                  options={{
                    cardType: CARD_TYPE_VIBE,
                    orientation: this.props.orientation,
                    containerMode: this.props.containerMode,
                    cardSlug: `style/${data.slug}`,
                  }}
                  data={{
                    name: data.name,
                    thumbnailUrl: data.thumbnail_url,
                    thumbnailAlt: `Wedding Business: ${data.name}`,
                    colorBar: LSTVGlobals.CARD_LABEL_COLOR_VIBE,
                    imageOnly: false,
                    videos: data.weight,
                  }}
                />
              );
            } else if (this.props.cardType === LSTVGlobals.CARD_SECTION_BUSINESS) {
              recordNonRealTimeUserEvent(
                LSTVGlobals.USER_NRT_REPORT_CARD_IMPRESSION,
                LSTVGlobals.USER_NRT_REPORT_BUFFER_SCOPE_DAY,
                {
                  slug: data.slug,
                  type: this.props.cardType,
                }
              );

              return (
                <VendorCard
                  key={data.slug}
                  slug={data.slug}
                  name={data.name}
                  thumbnailUrl={data.card_thumbnail_url}
                  thumbnailAlt={`Wedding Business: ${data.name}`}
                  premium={data.premium}
                  location={getLocationFullName(
                    data.business_locations?.length && !data.hide_location ? data.business_locations[0] : null
                  )}
                  roles={data.roles}
                  avatarUrl={data.profile_image}
                  business={data}
                  extraThumbnailUrls={data.extra_thumbnail_urls}
                  awards={data.awards}
                  weddingTeamContactUsUrl={data.wedding_team_contact_us_url}
                />
              );
            } else if (this.props.cardType === LSTVGlobals.CARD_SECTION_ARTICLE) {
              recordNonRealTimeUserEvent(
                LSTVGlobals.USER_NRT_REPORT_CARD_IMPRESSION,
                LSTVGlobals.USER_NRT_REPORT_BUFFER_SCOPE_DAY,
                {
                  slug: data.slug,
                  type: this.props.cardType,
                }
              );

              return (
                <ArticleCard
                  key={index}
                  linkUrl={`/${data.slug}`}
                  title={data.title}
                  caption={formatDate(data.publish_date)}
                  subtitle={data.synopsis}
                  thumbnailUrl={data.thumbnail_url}
                  thumbnailAlt={data.title}
                  authorSlug={data.author_slug}
                  authorName={data.author}
                  tags={getVibesForCard(data.tags, 2)}
                />
              );
            } else return <div key={index}> {this.props.cardType} </div>;
          })
          .filter((data, index) => {
            return index < this.props.numCards;
          });
      }

      // Eventually the plan is to get the ads from the server, but hard-coding for now.
      const { page_type, location } = this.context.payload;
      if (page_type === 'search_results' && location && /^united-states\/(new-york|nevada)(?:\/.*)?$/.test(location)) {
        cards = [
          <EventParams key="ad" ad_type="card" ad_client="KWIAT">
            <TrackImpression>
              <LSTVCard
                data={{
                  cardUrl: 'https://kwiat.com/',
                  name: 'KWIAT',
                  subtitle: 'Diamonds That Look Bigger and Brighter',
                  footerCaption: 'Shop bridal rings',
                  thumbnailUrl: '/images/kwiat-hands.jpg',
                  thumbnailAlt: 'Bridal rings',
                }}
                options={{
                  cardType: CARD_TYPE_AD,
                  orientation: this.props.orientation,
                  containerMode: this.props.containerMode,
                }}
              />
            </TrackImpression>
          </EventParams>,
          ...cards,
        ];
      }

      if (this.props.cardType !== LSTVGlobals.CONTENT_GRID_CONTENT_TYPE_PHOTO) {
        return this.props.containerMode === 'grid' ? (
          <CardGridStyle $numColumns={this.props.numColumns}>{cards}</CardGridStyle>
        ) : (
          <HorizontalScroll items={cards} />
        );
      } else {
        this.props.fixedItems.forEach((d) => {
          recordNonRealTimeUserEvent(
            LSTVGlobals.USER_NRT_REPORT_CARD_IMPRESSION,
            LSTVGlobals.USER_NRT_REPORT_BUFFER_SCOPE_DAY,
            {
              slug: d.id,
              type: this.props.cardType,
            }
          );
        });

        return (
          <PhotoGallery
            payload={this.props.payload}
            title={this.props.title}
            images={this.props.fixedItems}
            content={this.props.content}
            numCards={this.props.numCards}
          />
        );
      }
    } else return null;
  }
}

CardGrid.propTypes = {
  content: PropTypes.array,
  cardType: PropTypes.string,
  imageOnly: PropTypes.bool,
  numCards: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  fixedItems: PropTypes.array,
  numMasonryColumns: PropTypes.number,
  numColumns: PropTypes.object,
  payload: PropTypes.array,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
  containerMode: PropTypes.oneOf(['grid', 'h-scroll']),
  constraints: PropTypes.string,
  forceChangeCards: PropTypes.bool,
};

CardGrid.defaultProps = {
  verbosity: LSTVGlobals.CONTENT_CARD_VERBOSITY_LEVEL_MAXIMUM,
  carouselForMobile: true,
  name: null,
  options: {},
  containerMode: 'grid',
  imageOnly: true,
  orientation: 'portrait',
  forceChangeCards: false,
};

export default CardGrid;
